import React from 'react'
import { createContext, useContext, useState } from 'react'

const GlobalContext = createContext({
  showSidebar: false,
})

const GlobalProvider = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(false)

  return (
    <GlobalContext.Provider value={{ showSidebar, setShowSidebar }}>
      {children}
    </GlobalContext.Provider>
  )
}

const useGlobalContext = () => {
  return useContext(GlobalContext)
}

export { GlobalContext, GlobalProvider, useGlobalContext }
