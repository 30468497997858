exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-digital-marketing-practice-simulation-exercises-js": () => import("./../../../src/pages/digital_marketing_practice_simulation_exercises.js" /* webpackChunkName: "component---src-pages-digital-marketing-practice-simulation-exercises-js" */),
  "component---src-pages-e-commerce-game-js": () => import("./../../../src/pages/e-commerce-game.js" /* webpackChunkName: "component---src-pages-e-commerce-game-js" */),
  "component---src-pages-fr-blog-js": () => import("./../../../src/pages/fr/blog.js" /* webpackChunkName: "component---src-pages-fr-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-licensing-js": () => import("./../../../src/pages/licensing.js" /* webpackChunkName: "component---src-pages-licensing-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-marketing-simulation-software-for-university-students-js": () => import("./../../../src/pages/marketing_simulation_software_for_university_students.js" /* webpackChunkName: "component---src-pages-marketing-simulation-software-for-university-students-js" */),
  "component---src-pages-marketing-training-tool-for-employees-js": () => import("./../../../src/pages/marketing_training_tool_for_employees.js" /* webpackChunkName: "component---src-pages-marketing-training-tool-for-employees-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-recover-password-js": () => import("./../../../src/pages/recover_password.js" /* webpackChunkName: "component---src-pages-recover-password-js" */),
  "component---src-pages-referrals-affiliate-program-js": () => import("./../../../src/pages/referrals_affiliate_program.js" /* webpackChunkName: "component---src-pages-referrals-affiliate-program-js" */),
  "component---src-pages-request-demo-js": () => import("./../../../src/pages/request_demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-simulations-js": () => import("./../../../src/pages/simulations.js" /* webpackChunkName: "component---src-pages-simulations-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-vlog-js": () => import("./../../../src/pages/vlog.js" /* webpackChunkName: "component---src-pages-vlog-js" */),
  "component---src-templates-blog-post-blog-post-jsx": () => import("./../../../src/templates/blog_post/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-jsx" */),
  "component---src-templates-certificate-certificate-jsx": () => import("./../../../src/templates/certificate/Certificate.jsx" /* webpackChunkName: "component---src-templates-certificate-certificate-jsx" */),
  "component---src-templates-simulation-simulation-jsx": () => import("./../../../src/templates/simulation/Simulation.jsx" /* webpackChunkName: "component---src-templates-simulation-simulation-jsx" */)
}

